import React, { useEffect, useState } from "react"
import { Post } from "@fider/models"
import { actions, Result } from "@fider/services"
import { Loader } from "@fider/components"

export const BetaPosts: React.FC = () => {
  const [betaPosts, setBetaPosts] = useState<Post[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchBetaPosts = async () => {
      try {
        const result: Result<Post[]> = await actions.searchPosts({ view: "recent", tags: ["beta"] })
        if (result.ok) {
          setBetaPosts(result.data)
        } else {
          console.error("Error fetching beta posts:", result.error)
        }
      } catch (error) {
        console.error("Error fetching beta posts:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchBetaPosts()
  }, [])

  if (loading) {
    return <Loader />
  }

  return betaPosts.length > 0 ? (
    <div
      className="c-beta-posts"
      style={{
        paddingBottom: "20px",
      }}
    >
      <h3
        className="c-beta-posts-title"
        style={{
          fontWeight: "bold",
          paddingTop: "50px",
          textAlign: "center",
          fontSize: "23px",
        }}
      >
        Public beta tests
      </h3>
      <div className="c-markdown">
        From time to time there's a public beta test version available. If you want to, you can download the beta version and try out the new features, provide feedback and share your ideas
      </div>
      <ul
        className="c-beta-posts-list"
        style={{
          listStyle: "none",
          marginLeft: "0px",
          paddingLeft: "0px",
          textAlign: "center",
          fontWeight: "500",
        }}
      >
        {betaPosts.map((post) => (
          <li key={post.number} className="c-beta-posts-item">
            <a
              href={`/posts/${post.number}/${post.slug}`}
              className="c-beta-posts-link"
              style={{
                color: "#005FE3",
                fontWeight: "bold"
              }}
            >
              {post.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  ) : null
}
